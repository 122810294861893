<template>
  <el-sub-menu :index="'/casew/' + menu.categoryId" v-if="menu.children">
    <template #title>
      <!-- <el-icon><i-ep-menu /></el-icon> -->
      <span>{{ menu.name }}</span>
    </template>
    <CaseLeftSubMenu
      :menu="menuItem"
      v-for="menuItem in menu.children"
      :key="menuItem.categoryId"
    ></CaseLeftSubMenu>
  </el-sub-menu>
  <el-menu-item :index="'/casew/' + menu.categoryId" v-else>
    <!-- <el-icon><i-ep-menu /></el-icon> -->
    <template #title>{{ menu.name }}</template>
  </el-menu-item>
</template>

<script>
export default {
  name: "CaseLeftSubMenu",
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    }
  },
}
</script>

<style lang='' scoped>

</style>
