<template id="caseCss">
  <div class="container">
    <!-- 面包屑 -->
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>行业案例</BaseBreadItem>
    </BaseBread>

    <CaseBanner/>
    <div class="product-list">
      <!-- 左：侧边栏组件 -->
      <CaseLeftMenu :menuList="menuList"/>
      <!-- 右：内容 -->
      <!--      <CaseInfo></CaseInfo>-->
      <div class="article">
        <!-- 三级路由的出口 -->
        <div>
          <div class="case-list">
            <div v-if="loading" class="loading"></div>
            <div class="none" v-if="!loading && caseList.length === 0">

              <div class="case-none">
                <img src="@/assets/images/none.png" alt="加载失败"/>
                <p>暂无数据</p>

              </div>

            </div>
            <CaseList :aCase="caseList" v-else/>
          </div>
          <!-- 分页组件 -->
          <BasePagination
            v-if="total>0"
            :current-page="reqParams.pageIndex"
            :page-size="reqParams.pageSize"
            :total="total"
            @current-change="changePagerFn"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import {ref, reactive, watch} from "vue";
import {findAd} from '@/api/home'
import {findCaseByCategoryId, findCaseCategory} from "@/api/case";
import CaseLeftMenu from "@/views/casew/components/case-left-menu.vue";
import CaseList from "./components/case-list.vue";
import BasePagination from "@/components/library/base-pagination.vue";
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import CaseBanner from "@/views/casew/components/case-banner.vue";

export default {
  name: 'CaseHome',
  components: {CaseBanner, BaseBreadItem, BaseBread, BasePagination, CaseLeftMenu, CaseList},
  props: {
    aCase: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const dataSource = ref([])
    findAd("htmlCaseImage").then(data => {
      dataSource.value = data.data.list[0]
    })
    const menuList = ref([]);
    findCaseCategory().then(data => {
      menuList.value = data.data.list
    })
    // 获取当前路由对象
    const route = useRoute();

    const loading = ref(false)
    const total = ref(0)
    const caseList = ref([]);
// 筛选条件
    const reqParams = reactive({
      pageIndex: 1,
      pageSize: 4,
    })
    watch(() => route.params.id, (caseVal) => {
      if (caseVal) {
        reqParams.categoryId = caseVal
        reqParams.pageIndex = 1
      }
      findCaseByCategoryId(reqParams).then(data => {
        loading.value = true
        caseList.value = data.data.records
        total.value = data.data.total
        loading.value = false
      })
    }, {immediate: true})

    // 实现分页切换
    const changePagerFn = (newPage) => {
      reqParams.pageIndex = newPage
      findCaseByCategoryId(reqParams).then(data => {
        loading.value = true
        caseList.value = data.data.records
        total.value = data.data.total
        loading.value = false
      })
    }
    return {menuList, caseList, total, reqParams, loading, changePagerFn, dataSource}
  }
}

</script>

<style scoped lang="less">
.product-list {
  display: flex;

  .article {
    width: 100%;
  }
}

.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;

  .info {
    padding-left: 10px;
    flex: 1;

    p {
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }

      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}

.case-oneimage img {
  height: 250px;
  width: 100%;
}

.case-none {
  text-align: center;
  padding: 150px 0;
  background: #fff;

  img {
    width: 180px;
  }

  p {
    color: #999999;
    padding: 20px 0;
  }
}

</style>
