<template>
  <div class="case-banner">
    <BaseCarousel auto-play :sliders="sliders"/>
  </div>
</template>
<script>
import {ref} from 'vue'
import {findAd} from '@/api/home'
import BaseCarousel from "@/components/library/base-carousel.vue";

export default {
  name: 'CaseBanner',
  components: {BaseCarousel},
  setup() {
    // 获取轮播图数据
    const sliders = ref([])
    findAd("caseCarousel").then(data => {
      sliders.value = data.data.list
    })
    return {sliders}
  }
}
</script>
<style scoped lang="less">
.case-banner {
  width: 100%;
  height: 500px;
  position: relative;
  left: 0;
  top: 0;
  z-index: 98;
  margin: 0 0 50px 0;
}

// 覆盖样式
.base-carousel {
  :deep(.carousel-btn.prev) {
    // left: 270px;
  }

  :deep(.carousel-indicator) {
    // padding-left: 250px;
  }
}
</style>
